import Honeybadger from '@honeybadger-io/js';

window.exceptionHandler = Honeybadger;

if (process.env.RAILS_ENV === 'production') {
  window.exceptionHandler.configure({
    apiKey:
      process.env.FRONTEND_ASKER_HONEYBADGER_API_KEY ||
      process.env.HONEYBADGER_API_KEY,
    environment: process.env.RAILS_ENV,
    revision: process.env.HEROKU_RELEASE_VERSION,
  });
}
